import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "infinite-learning",
      "style": {
        "position": "relative"
      }
    }}>{`Infinite Learning`}<a parentName="h2" {...{
        "href": "#infinite-learning",
        "aria-label": "infinite learning permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "objective",
      "style": {
        "position": "relative"
      }
    }}>{`Objective`}<a parentName="h3" {...{
        "href": "#objective",
        "aria-label": "objective permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Continue your journey with Zodiac and learn about cross chain infrastructure. `}<strong parentName="p">{`This is an optional session hosted only in the `}<a parentName="strong" {...{
          "href": "https://discord.gg/wwmBWTgyEq"
        }}>{`Gnosis Guild Discord`}</a>{` for anyone to join.`}</strong></p>
    <h3 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h3" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://gnosis.github.io/zodiac/docs/tutorial-module-bridge/get-started"
        }}>{`Zodiac Bridge tutorial`}</a>{` (2021)`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/images/build/zodiac_system.png",
        "alt": "Zodiac Design Language"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      